html, body, #root {
  height: 100%;
  background-color: #f5f5f5; /* I don't like this here */
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

#root {
  /* background-image: url('https://images.unsplash.com/photo-1498830204877-434556b153df?ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80');
  background-size: cover; */
}